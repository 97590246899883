import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import Media from "react-md/lib/Media/Media";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import List from "react-md/lib/Lists/List";
import ListItem from "react-md/lib/Lists/ListItem";
import Avatar from "react-md/lib/Avatars/Avatar";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../layout'

const Mission = () => (

  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "careers.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Media>
              <Img fluid={data.Image.childImageSharp.fluid} />
            </Media>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="WE’RE HIRING NOW!" />
              <CardText>
                <p>
                  Mindful Montessori Early Learning Center is hiring Montessori
                  Primary Teacher for 3 to 6 years Program.
                </p>
                <p>
                  We are in search of AMI certified, experienced Montessori
                  Primary teacher who are qualified, enthusiastic and nurturing
                  individuals who will be responsible for presenting lessons by using
                  authentic Montessori method of instruction and communicate with
                  all key stakeholders and respond to the needs of families and
                  students in their class.
                </p>
                <p>
                  It’s a great opportunity for teachers with at least 1 year of teaching
                  experience who have a passion for education, an entrepreneurial
                  spirit, a solutions-oriented attitude, and comfort with charting a
                  new path in education.
                </p>
                <p>
                  Salaries are commensurate based upon experience and will be
                  discussed during the interview process, if selected.
                  If you think you would be a good fit, send your resume and if you
                  have any questions or want to learn more, please contact
                </p>
                <List>
                  <a href="mailto:lakhbir.deol@mindfulmontessori.com">
                    <ListItem
                      leftAvatar={<Avatar icon={<FontIcon>mail</FontIcon>} />}
                      primaryText="Lakhbir Deol"
                      secondaryText="lakhbir.deol@mindfulmontessori.com"
                    />
                  </a>
                  <a href="tel:+917702055564">
                    <ListItem
                      leftAvatar={<Avatar icon={<FontIcon>phone</FontIcon>} />}
                      primaryText="Mobile"
                      secondaryText="+91 77020 55564"
                    />
                  </a>
                </List>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default Mission
